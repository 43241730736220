import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledOutdoor = styled.div`
  background-color: ${props => props.theme.background.base};
  padding: 2em 0;
`;

const StyledOutdoorContainer = styled.div`
  background-color: ${props => props.theme.background.base};
  position: relative;
  padding: 4em 2em;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4em;
  align-items: flex-start;

  ${down('md')} {
      padding: 2em;
      grid-row-gap: 2em;
      grid-template-columns: 1fr;

      h1 {
        font-size: 38px !important;
      }
      
      h2 {
        font-size: 16px;
      }
      
      .fredText {
        width: 100%;
        
        h4 {
          margin-top: 0 !important;
        }
      }
  }
  
  .fredText {
    display: grid;
    grid-row-gap: 0.5em;
    
    h1 {
      line-height: 1;
    }
    
    h4 {
     margin-top: 2em;
    }
  }
  
  .highlight {
    color: ${props => props.theme.orange.base};
  }
`;

export { StyledOutdoor, StyledOutdoorContainer }