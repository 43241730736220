import React from 'react';
import Header from "../components/header/header";
import Hero from "../components/hero/hero";
import Fred from "../components/fred/fred";
import Advantages from "../components/advantages/advantages";
import Outdoor from "../components/outdoor/outdoor";
import Copyright from "../components/copyright/styledCopyright";
import FridaTeaser from "../components/frida/teaser";

function HomeLayout() {
    return (
        <>
            <Header/>
            <Hero/>
            <FridaTeaser/>
            <Fred/>
            <Advantages/>
            <Outdoor/>
            <Copyright/>
        </>
    )
}
export default HomeLayout;