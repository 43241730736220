import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledAdvantages = styled.div`
  background-color: ${props => props.theme.background.dark};
  display: flex;
  width: 100%;
  padding: 4em 0;
  
  ${down('md')} {
    padding: 2em 0;
  }
  
  .headline {
    text-align: center;
    color: white;
    margin-top: 2em;
    padding: 0 2em;
    
    h4 {
      color: ${props => props.isFrida ? props.theme.pink.base : props.theme.orange.base};
    }
    
    ${down('md')} {
      text-align: left;
      h1 {
        font-size: 28px !important;
      }
    }
  }
`;

const StyledAdvantagesContainer = styled.div`
  background-color: ${props => props.theme.background.dark};
  position: relative;
  padding: 4em 2em;
  color: white;
  display: grid;
  grid-column-gap: 2em;
  align-items: center;
  
  ${down('md')} {
    padding: 2em;
  }
`;

const StyledAdvantageItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 2.5em;
  grid-row-gap: 4em;
  align-content: flex-start;
  
  ${down('md')} {
    grid-gap: 2em;
    grid-template-columns: 1fr;
  }
`;

const StyledAdvantageItem = styled.div`
  display: grid;
  align-content: flex-start;
  
 .advantageIcon {
  font-size: 2em;
  
  ${down('md')} {
    font-size: 1.5em;
  }
 }
 
  h2 {
    color: ${props => props.isFrida ? props.theme.pink.base : props.theme.orange.base};
    font-weight: 700;
  }
  
  grid-gap: 1em;
`;

export {StyledAdvantages, StyledAdvantagesContainer, StyledAdvantageItems, StyledAdvantageItem}