import React from 'react';
import Header from "../components/header/header";
import Imprint from "../components/imprint/imprint";
import Copyright from "../components/copyright/styledCopyright";

function ImprintLayout() {
    return (
        <>
            <Header/>
            <Imprint/>
            <Copyright/>
        </>
    )
}
export default ImprintLayout;