import React from "react";
import Routes from "./routes";
import {connect} from "react-redux";
import {ThemeProvider} from "styled-components";
import GlobalStyle from "./styles/globalStyle";

class AppThemeProvider extends React.Component {
    render() {
        return (
            <ThemeProvider theme={this.props.theme}>
                <GlobalStyle/>
                <Routes/>
            </ThemeProvider>
        )
    }
}

const mapStateToProps = state => ({
    theme: state.commonReducer.theme
});

export default connect(mapStateToProps)(AppThemeProvider);