import styled from "styled-components";
import {down} from "styled-breakpoints";

const StyledFrida = styled.div`
  background-color: ${props => props.theme.background.base};
  padding: 2em;
`;

const StyledFridaContainer = styled.div`
  background-color: ${props => props.theme.background.base};
  position: relative;
  padding: 4em 2em 0 2em;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  align-items: center;
  
  ${down('md')} {
      padding: 2em 0 0 0;
      grid-template-columns: 1fr;

      h1 {
        font-size: 38px !important;
      }
      
      h2 {
        font-size: 16px;
      }
      
      .fredImage {
        display: none;
      }
      
      .fredText {
        width: 100%;
        
        h4 {
          margin-top: 0 !important;
        }
      }
      
      .fredMobileImage {
        display: flex !important;
        margin-top: 2em;
        
        img {
          border-radius: 4px;
          object-position: center !important;
          width: 100%;
          object-fit: cover;
        }
      }
      
      .fredMobileImages {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 0.5em;
        margin-top: 1em;
        
        img {
          border-radius: 4px;
          width: 100%;
          object-fit: cover;
        }
      }
  }
  
  .fredImage {
    padding: 4em;
    img {
      width: 100%;
    }
  }
  
  .fredText {
    display: grid;
    grid-row-gap: 0.5em;
    
    h1 {
      line-height: 1;
    }
    
    h4 {
     margin-top: 2em;
    }
  }
  
  .button {
    display: grid;
    grid-template-columns: auto auto;
    padding: 0.5em 1em;
    margin-top: 1em;
    align-items: center;
    background-color: ${props => props.theme.pink.base};
    border-radius: 4px;
    justify-self: flex-start;
    color: white;
    grid-column-gap: 1em;
    
    h4 {
      margin-top: 0;
    }
  }
  
  .highlight {
    color: ${props => props.isFrida ? props.theme.pink.base : props.theme.orange.base};
  }
  
  .fredMobileImage {
    display: none;
  }
  
  .fredMobileImages {
    display: none;
  }
`;

const StyledHero = styled.div`
  width: 100%;
  padding: 2em 0;
  z-index: 0;
  border-bottom: 1px solid ${props => props.theme.background.divider};
  
  ${down('md')} {
    padding: 0;
  }
`;

const StyledHeroContainer = styled.div`
  width: 100%;
  height: ${props => props.isFrida ? 'auto' : '600px'};
  border-radius: 12px;
  position: relative;
  
  ${down('md')} {
    height: ${props => props.isFrida ? '500px' : '400px'};
    
    .overlay {
      border-radius: 0 !important;
    }
    
    img {
      border-radius: 0 !important;
    }
  }
  
  .button {
    display: grid;
    grid-template-columns: auto auto;
    padding: 1em 2em;
    margin-top: 1em;
    align-items: center;
    background-color: ${props => props.theme.pink.base};
    border-radius: 4px;
    justify-self: flex-start;
    color: white;
    grid-column-gap: 1em;
    
    h3 {
      color: white !important;
    }
  }
  
  .headline {
    position: absolute;
    display: grid;
    grid-row-gap: ${props => props.isFrida ? '1em' : '0.5em'};
    left: 10%;
    bottom: ${props => props.isFrida ? '15%' : '20%'};
    color: ${props => props.theme.background.base};

    ${down('md')} {
      h1 {
        font-size: 38px !important;
      }
      
      h2 {
        font-size: 16px;
      }
      
      h3 {
        font-size: 16px;
      }
      
      left: 2em;
      bottom: 3em;
    }
    
    h3 {
      color: ${props => props.isFrida ? props.theme.pink.base : props.theme.orange.base};
    }
      
    .locked {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 0.5em;
      font-size: 1.2em;
      align-items: center;
    }
    
    h1 {
      font-size: 64px;
      line-height: 1;
      font-weight: 700;
      color: ${props => props.theme.background.base};
    }
    
    h2 {
      line-height: 1;
      color: ${props => props.isFrida ? props.theme.pink.base : props.theme.orange.base};
    }
  }
  
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 12px;
    height: 100%;
    background: linear-gradient(0deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0) 80%);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
`;

export { StyledFrida, StyledHero, StyledHeroContainer, StyledFridaContainer }