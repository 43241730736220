import React from 'react';
import Header from "../components/header/header";
import Copyright from "../components/copyright/styledCopyright";
import FridaHero from "../components/frida/hero";
import FridaAdvantages from "../components/frida/advantages";
import FridaHero2 from "../components/frida/hero2";

function FridaLayout() {
    return (
        <>
            <Header/>
            <FridaHero/>
            <FridaAdvantages/>
            <FridaHero2/>
            <Copyright/>
        </>
    )
}
export default FridaLayout;