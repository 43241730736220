import React from 'react';
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import commonReducer from "./components/common/reducer";
import AppThemeProvider from "./themeProvider";

const Store = createStore(
    combineReducers({commonReducer})
);

function App() {
  return (
      <Provider store={Store}>
        <AppThemeProvider/>
      </Provider>
  );
}

export default App;