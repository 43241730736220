import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledFred = styled.div`
  background-color: ${props => props.theme.background.base};
  padding: 2em;
`;

const StyledFredContainer = styled.div`
  background-color: ${props => props.theme.background.base};
  position: relative;
  padding: 0 2em;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2em;
  align-items: center;
  
  ${down('md')} {
      padding: 2em 0;
      grid-template-columns: 1fr;

      h1 {
        font-size: 38px !important;
      }
      
      h2 {
        font-size: 16px;
      }
      
      .fredImage {
        display: none;
      }
      
      .fredText {
        width: 100%;
        
        h4 {
          margin-top: 0 !important;
        }
      }
      
      .fredMobileImage {
        display: flex !important;
        margin-top: 2em;
        
        img {
          border-radius: 4px;
          width: 100%;
          height: 300px;
          object-fit: cover;
        }
      }
      
      .fredMobileImages {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 0.5em;
        margin-top: 1em;
        
        img {
          border-radius: 4px;
          width: 100%;
          object-fit: cover;
        }
      }
  }
  
  .fredImage {
    padding: 4em;
    img {
      width: 100%;
    }
  }
  
  .fredText {
    display: grid;
    grid-row-gap: 0.5em;
    
    h1 {
      line-height: 1;
    }
    
    h4 {
     margin-top: 2em;
    }
  }
  
  .highlight {
    color: ${props => props.theme.orange.base};
  }
  
  .fredMobileImage {
    display: none;
  }
  
  .fredMobileImages {
    display: none;
  }
`;

export { StyledFred, StyledFredContainer }