import React, {useState, useEffect} from 'react';
import {StyledHeader, StyledLogoContainer, StyledMenu, StyledSocials, StyledContactButton, StyledHeaderContainer} from "./stlyedHeader";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import { ShieldFillCheck, EnvelopeFill, List } from "react-bootstrap-icons";
import { ReactComponent as Whatsapp } from "../../styles/icons/whatsapp.svg";
import {Link } from 'react-router-dom';
import Sidebar from "../common/burger";
import OffCanvas from 'react-aria-offcanvas'

function Header() {

    const [showHeaderBlur, setHeaderBlur] = useState(false);
    const [openPanel, setOpenPanel] = useState(false);

    useEffect(() => {
        function handleResize() {
            if (window.pageYOffset > 32 && !showHeaderBlur) {
                setHeaderBlur(true)
            }
            if (window.pageYOffset < 32 && showHeaderBlur) {
                setHeaderBlur(false)
            }
        }
        window.addEventListener('scroll', handleResize);
    })

    return (
        <StyledHeader showHeaderBlur={showHeaderBlur}>
            <StyledMaxWidthContainer>
                <StyledHeaderContainer>
                    <Link to={'/'}>
                    <StyledLogoContainer>
                        <h2 className={'shield'}><ShieldFillCheck/></h2>
                        <div className={'slogan'}><h3>Thunert-Sicherheit</h3></div>
                    </StyledLogoContainer>
                    </Link>
                    <StyledMenu>
                        <Link to={'/'}><h4>Home</h4></Link>
                        <Link to={'/frida'}>
                            <h4>Frida</h4>
                            <div className={'newBadge'}>
                                <h5>NEU!</h5>
                            </div>
                        </Link>
                    </StyledMenu>
                    <StyledSocials>
                        <a target={'_blank'} rel="noreferrer" href={'https://wa.me/491634000023?text=Ich%20interessiere%20mich%20für%20ein%20Frühwarnsystem!'}><Whatsapp/></a>
                        <a target={'_blank'} rel="noreferrer" href={'mailto:info@thunert-sicherheit.de'}><EnvelopeFill/></a>
                    </StyledSocials>
                    <Link to={'/kontakt'}>
                    <StyledContactButton>
                        <h5>Kontakt</h5>
                    </StyledContactButton>
                    </Link>
                    <div className={'list'} onClick={() => setOpenPanel(true)}><List/></div>
                    <OffCanvas
                        isOpen={openPanel}
                        position={'right'}
                        closeOnOverlayClick
                        height={'100%'}
                        overlayClassName={'panelOverlay'}
                        onClose={() => setOpenPanel(false)}
                    >
                        <Sidebar/>
                    </OffCanvas>
                </StyledHeaderContainer>
            </StyledMaxWidthContainer>
        </StyledHeader>

    )
}

export default Header;