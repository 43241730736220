import React from "react";
import {StyledHero, StyledHeroContainer} from "./styledHero";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import HeroImage from '../../styles/images/hero-1.png';
import { LockFill } from "react-bootstrap-icons";

function Hero() {
    return (
        <StyledHero>
            <StyledMaxWidthContainer>
                <StyledHeroContainer>
                    <div className={'overlay'}/>
                    <div className={'headline'}>
                        <h3>Suritec-Frühwarnsystem</h3>
                        <h1>Sicherheit made <br/>  in Germany</h1>
                        <div className={'locked'}>
                            <LockFill/>
                            <h2>Einbruchschutz ohne Montage</h2>
                        </div>
                    </div>
                    <img src={HeroImage} alt="hero-1"/>
                </StyledHeroContainer>
            </StyledMaxWidthContainer>
        </StyledHero>
    )
}

export default Hero;