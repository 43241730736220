import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledCopyright = styled.div`
  background-color: ${props => props.theme.background.dark};
  border-top: 1px solid ${props => props.theme.background.divider};
`;

const StyledCopyRightContainer = styled.div`
  padding: 2em;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 0.5em;
  align-items: center;
  color: white;
  
  ${down('md')} {
    grid-template-columns: 1fr auto;
  }
  
  .copyrightMenu {
    justify-self: center;
    display: grid;
    grid-column-gap: 0.5em;
    grid-template-columns: auto auto auto;
    
    a {
      color: white;
    }
    
    ${down('md')} {
      display: none;
    }
  }
  
  ${down('md')} {
    padding: 1em 2em;
  }
`;

export { StyledCopyright, StyledCopyRightContainer }