import { lightTheme } from "../../styles/light";
import { SWITCH_THEME } from "./actions";
import {darkTheme} from "../../styles/dark";
const localTheme = window.localStorage.getItem('theme');

const activeTheme = () => {
    let isDarkTheme = ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) && localTheme !== 'light') ||
        localTheme === 'dark';
    return isDarkTheme ? darkTheme : lightTheme;
};

const initialState = {
    theme: darkTheme
};

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case SWITCH_THEME:
            let theme;
            if (action.payload === 'light') {
                window.localStorage.setItem('theme', 'dark');
                theme = { ...darkTheme};
            } else {
                window.localStorage.setItem('theme', 'light');
                theme = { ...lightTheme};
            }
            let newState = {
                ...state,
                theme: theme
            };
            return newState;
        default:
            return state;
    }
};

export default commonReducer;