import React from "react";
import {StyledOutdoor, StyledOutdoorContainer} from "./styledOutdoor";
import {StyledMaxWidthContainer} from "../../styles/styledBody";

function Outdoor() {
    return (
        <StyledOutdoor>
            <StyledMaxWidthContainer>
                <StyledOutdoorContainer>
                    <div>
                        <div className={'fredText'}>
                        <h2 className={'highlight'}>Außensirene</h2>
                        <h1>Abschreckend. Schön!</h1>
                        <h4>Die Außensirene ist die perfekte Ergänzung zur SURITEC FR.ED. Sie macht den Alarm des Frühwarnsystems auch von außen optisch und akustisch deutlich bemerkbar.</h4>
                    </div>
                    </div>
                    <div>
                        <div className={'fredText'}>
                            <h2 className={'highlight'}>Fernbedienung</h2>
                            <h1>Spürbar. Einfach!</h1>
                            <h4>Die Fernbedienung gibt bei jeder vollständigen Signalübermittlung ein Vibrations-Feedback an den Nutzer weiter. Dadurch fühlen Sie, ob das Frühwarnsystem auch wirklich in den gewünschten Modus geschaltet wurde.</h4>
                        </div>
                    </div>
                </StyledOutdoorContainer>
            </StyledMaxWidthContainer>
        </StyledOutdoor>
    )
}

export default Outdoor;