import React from "react";
import {StyledHero, StyledHeroContainer} from "./styledFrida";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import HeroImage from '../../styles/images/clear.png';
import {ArrowRightCircleFill} from "react-bootstrap-icons";
import {Link} from "react-router-dom";

function FridaHero2() {
    return (
        <StyledHero isFrida>
            <StyledMaxWidthContainer>
                <StyledHeroContainer isFrida>
                    <div className={'overlay'}/>
                    <div className={'headline'}>
                        <h2>Mit Industriestandard-Lüftertechnik</h2>
                        <h1>5-Dimensionales <br/> Flächenhygiene <br/> und Raumluftkonzept</h1>
                        <div className={'locked'}>
                            <h3>&#8226; Luftreinigung mit HEPA-Filter</h3>
                        </div>
                        <div className={'locked'}>
                            <h3>&#8226; Viren-Deaktivierung durch UV-C Bestrahlung</h3>
                        </div>
                        <div className={'locked'}>
                            <h3>&#8226; DiOMix: Sofort Neutralisierung der Aerosole in der Raumluft</h3>
                        </div>
                        <div className={'locked'}>
                            <h3>&#8226; Desinfektion der Oberflächen und Gegenstände</h3>
                        </div>
                        <div className={'locked'}>
                            <h3>&#8226; Raumbeduftung mit natürlichen Aromen</h3>
                        </div>
                        <Link to={'/kontakt'} className={'button'}>
                            <h3>Jetzt anfragen</h3>
                            <ArrowRightCircleFill/>
                        </Link>
                    </div>
                    <img src={HeroImage} alt="hero-1"/>
                </StyledHeroContainer>
            </StyledMaxWidthContainer>
        </StyledHero>
    )
}

export default FridaHero2;