import {StyledAdvantages, StyledAdvantagesContainer, StyledAdvantageItem, StyledAdvantageItems} from "../advantages/styledAdvantages";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import {CheckCircle} from "react-bootstrap-icons";

function FridaAdvantages() {
    return (
        <StyledAdvantages isFrida>
            <StyledMaxWidthContainer>
                <div className={'headline'}>
                    <h1>Das ist FRIDA™</h1>
                    <h4>... wirkt bakterizid, fungizid und viruzid!</h4>
                </div>
                <StyledAdvantagesContainer>
                    <StyledAdvantageItems>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>GEGEN VIREN</h2>
                            <h4>Kann auch Viren unter 0,1 µm töten</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>RAUMGRÖSSE</h2>
                            <h4>Bis zu 100 m²</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>VOLUMENSTROM</h2>
                            <h4>Max. Lüfterleistung 900m³/h</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>DIOMIX-TECHNOLOGIE
                            </h2>
                            <h4>Zu- und abschaltbar</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>UV-C-LICHT</h2>
                            <h4>2x 35 Watt zu- und abschaltbar</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>H14 HEPA-FILTER</h2>
                            <h4>Gem. DIN EN 1822-1:2009</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>DEXPRESS-MODUS</h2>
                            <h4>Intensive Schnellreinigung mit Flächendesinfektion</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>TIMER</h2>
                            <h4>Auto-Programme mit Echtzeit u. Wochentagen</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem isFrida>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>NACHT-MODUS</h2>
                            <h4>Intensive Tiefenreinigung mit Sofort-Start o. Timer-Funktion</h4>
                        </StyledAdvantageItem>
                    </StyledAdvantageItems>
                </StyledAdvantagesContainer>
            </StyledMaxWidthContainer>
        </StyledAdvantages>
    )
}

export default FridaAdvantages;
