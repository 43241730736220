import React from "react";
import {StyledCopyright, StyledCopyRightContainer} from "./copyright";
import dayjs from "dayjs";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import { EnvelopeFill } from "react-bootstrap-icons";
import { ReactComponent as Whatsapp } from "../../styles/icons/whatsapp.svg";
import {StyledSocials} from "../header/stlyedHeader";
import {Link } from 'react-router-dom';

function Copyright() {
    return (
        <StyledCopyright>
            <StyledMaxWidthContainer>
                <StyledCopyRightContainer>
                    <div className={'imprint'}>
                        <h5>© {dayjs().format('YYYY')} | Thunert-Sicherheit</h5>
                    </div>
                    <div className={'copyrightMenu'}>
                        <Link to={'/impressum'}><h5>Impressum</h5></Link>
                        <Link to={'/agb'}><h5>AGB</h5></Link>
                        <Link to={'/datenschutz'}><h5>Datenschutz</h5></Link>
                    </div>
                    <StyledSocials>
                        <a target={'_blank'} rel="noreferrer" href={'https://wa.me/491634000023?text=Ich%20interessiere%20mich%20für%20ein%20Frühwarnsystem!'}><Whatsapp/></a>
                        <a target={'_blank'} rel="noreferrer" href={'mailto:info@thunert-sicherheit.de'}><EnvelopeFill/></a>
                    </StyledSocials>
                </StyledCopyRightContainer>
            </StyledMaxWidthContainer>
        </StyledCopyright>
    )
}

export default Copyright