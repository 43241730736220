import React from "react";
import {StyledFrida, StyledFridaContainer} from "./styledFrida";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import FridaImage from '../../styles/images/frida-website.png';
import FredImageMobile from '../../styles/images/frida-mobile-1.png';
import FredImageMobile1 from '../../styles/images/frida-mobile-2.png';
import FredImageMobile2 from '../../styles/images/frida-image-3.png';
import FredImageMobile3 from '../../styles/images/frida-mobile-4.png';
import { ArrowRightCircleFill } from "react-bootstrap-icons";
import {Link} from "react-router-dom";

function FridaTeaser() {
    return (
        <StyledFrida isFrida>
            <StyledMaxWidthContainer>
                <StyledFridaContainer isFrida>
                    <div className={'fredText'}>
                        <h2 className={'highlight'}>FRIDA™ - DIE WELTNEUHEIT</h2>
                        <h1>DESINFIZIERT AEROSOLE & FLÄCHEN IM GANZEN RAUM</h1>
                        <h4>FRIDA™ ist mehr als ein Luftreiniger: Durch die Kombination verschiedener, hochwirksamer Filter- und Desinfektionstechnologien werden sowohl die Luft als auch Oberflächen im Raum schnell und einfach gereinigt und desinfiziert. FRIDA™ ist ideal für Räume, die von vielen Menschen frequentiert werden und geht aktiv gegen Viren, Bakterien, Aerosole, Keime, Sporen, Pilze etc. vor. Krankheitserregende Viren wie Corona- oder Influenza-Viren können neutralisiert werden – und damit das Risiko einer Ansteckung mit Krankheiten  minimiert werden.</h4>
                        <Link to={'/frida'} className={'button'}>
                            <h4>Weitere Infos</h4>
                            <ArrowRightCircleFill/>
                        </Link>
                    </div>
                    <div className={'fredMobileImage'}>
                        <img src={FredImageMobile} alt='fred-mobile'/>
                    </div>
                    <div className={'fredMobileImages'}>
                        <img src={FredImageMobile3} alt='fred-mobile'/>
                        <img src={FredImageMobile1} alt='fred-mobile'/>
                        <img src={FredImageMobile2} alt='fred-mobile'/>
                    </div>
                    <div className={'fredImage'}>
                        <img src={FridaImage} alt='fred'/>
                    </div>
                </StyledFridaContainer>
            </StyledMaxWidthContainer>
        </StyledFrida>
    )
}

export default FridaTeaser;