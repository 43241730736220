import {StyledAdvantages, StyledAdvantagesContainer, StyledAdvantageItem, StyledAdvantageItems} from "./styledAdvantages";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import { Tools, BatteryCharging, CheckCircle, Moon, Compass, Tree} from "react-bootstrap-icons";

function Advantages() {
    return (
        <StyledAdvantages>
            <StyledMaxWidthContainer>
                <div className={'headline'}>
                    <h1>Sicherheit neu definiert</h1>
                    <h4>Eine Alarmanlage schreckt 95 % aller Einbrecher ab!</h4>
                </div>
                <StyledAdvantagesContainer>
                    <StyledAdvantageItems>
                        <StyledAdvantageItem>
                            <Tools className={'advantageIcon'}/>
                            <h2>Keine Montage</h2>
                            <h4>Keine Kabel verlegen, keine Bauarbeiten, kein Dreck. Für den Betrieb genügt eine Steckdose.</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem>
                            <BatteryCharging  className={'advantageIcon'}/>
                            <h2>Sabotageschutz</h2>
                            <h4>Sollte ein Einbrecher versuchen den Strom im Gebäude lahm zu legen, schützt unsere Anlage im Akkubetrieb bis zu 48h ohne Stromzufuhr.</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem>
                            <CheckCircle  className={'advantageIcon'}/>
                            <h2>Einfache Bedienung</h2>
                            <h4>Bei der Entwicklung unseres Frühwarnsystems FR.ED wurde explizit auf eine intuitive Bedienung geachtet.</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem>
                            <Moon  className={'advantageIcon'}/>
                            <h2>Beruhigt schlafen</h2>
                            <h4>FR.ED verfügt über einen eigens entwickelten Nachtmodus, der dafür sorgt, dass Sie sich auch während Sie schlafen sicher und beschützt fühlen dürfen.</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem>
                            <Tree className={'advantageIcon'}/>
                            <h2>Klimaschutz-Partner</h2>
                            <h4>Für jedes verkaufte Frühwarnsystem wird ein Baum gepflanzt.</h4>
                        </StyledAdvantageItem>
                        <StyledAdvantageItem>
                            <Compass  className={'advantageIcon'}/>
                            <h2>Made in Germany</h2>
                            <h4>Wir vertrauen auf Qualität aus Deutschland: Jedes einzelne Frühwarnsystem wird komplett in Deutschland gefertigt. In unserer Manufaktur im Schwarzwald.</h4>
                        </StyledAdvantageItem>
                    </StyledAdvantageItems>
                </StyledAdvantagesContainer>
            </StyledMaxWidthContainer>
        </StyledAdvantages>
    )
}

export default Advantages;
