import React from "react";
import {StyledFred, StyledFredContainer} from "./styledFred";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import FredImage from '../../styles/images/fred.png';
import FredImageMobile from '../../styles/images/fred-mobile';
import FredImageMobile1 from '../../styles/images/sender';
import FredImageMobile2 from '../../styles/images/outdoor';
import FredImageMobile3 from '../../styles/images/at-night';

function Fred() {
    return (
        <StyledFred>
            <StyledMaxWidthContainer>
                <StyledFredContainer>
                    <div className={'fredImage'}>
                        <img src={FredImage} alt='fred'/>
                    </div>
                    <div className={'fredText'}>
                        <h2 className={'highlight'}>FR.ED - das EinbruchFrühWarnSystem</h2>
                        <h1>Für Ihre Familie<br/>& Immobilie</h1>
                        <h4>Mit dem Infraschall-Alarmsystem FR.ED lassen sich schnell und einfach Häuser, Wohnungen und Gewerbeobjekte mit bis zu 800m² – auch über mehrere Stockwerke vom Keller bis zum Dach – ohne zusätzliche Sensoren und Magnetkontakte überwachen.</h4>
                    </div>
                    <div className={'fredMobileImage'}>
                        <img src={FredImageMobile} alt='fred-mobile'/>
                    </div>
                    <div className={'fredMobileImages'}>
                        <img src={FredImageMobile3} alt='fred-mobile'/>
                        <img src={FredImageMobile1} alt='fred-mobile'/>
                        <img src={FredImageMobile2} alt='fred-mobile'/>
                    </div>
                </StyledFredContainer>
            </StyledMaxWidthContainer>
        </StyledFred>
    )
}

export default Fred;