export const lightTheme = {
    identifier: 'dark',
    breakpoints: {
        sm: '576px',
        md: '768px',
        lg: '992px',
        xl: '1200px',
    },
    background: {
        dark: '#0f0f13',
        blurred: 'rgba(15, 15, 19, 0.8)',
        base: '#222429',
        divider: '#33353a',
        white: 'white'
    },
    text: {
        color: 'white'
    },
    orange: {
        base: '#ff980d'
    },
    pink: {
        base: '#ff007f'
    }
}
