import React from "react";
import {StyledHero, StyledHeroContainer} from "./styledFrida";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import HeroImage from '../../styles/images/hero-2.png';
import { Flower1 } from "react-bootstrap-icons";

function FridaHero() {
    return (
        <StyledHero>
            <StyledMaxWidthContainer>
                <StyledHeroContainer isFrida>
                    <div className={'headline'}>
                        <h3>Glaubst Du das geht nicht?</h3>
                        <h1>Frida™ <br/>  Die Weltneuheit</h1>
                        <div className={'locked'}>
                            <Flower1/>
                            <h2>Desinfiziert Aerosole & Flächen im ganzen Raum</h2>
                        </div>
                    </div>
                    <img src={HeroImage} alt="hero-1"/>
                </StyledHeroContainer>
            </StyledMaxWidthContainer>
        </StyledHero>
    )
}

export default FridaHero;