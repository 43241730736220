import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const StyledMaxWidthContainer = styled.div`
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  height: 100%;
  
   ${down('md')} {
    max-width: none !important;
    margin-right: initial !important;
    margin-left: initial !important;
  }
`;

export { StyledMaxWidthContainer };