import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledImprint = styled.div`
  height: 100%;
  padding: 2em;
`;

const StyledImprintContainer = styled.div`
  position: relative;
  height: 100%;
  color: white;
  padding: 4em 2em;
  
  .imprint_1 {
    padding: 2em 0;
    display: grid;
  }
  
  .imprint_2 {
    padding: 2em 0;
    display: grid;
  }
  
  ${down('md')} {
    padding: 2em 0;
    
    .imprint_1 {
      padding: 2em 0 0 0;
    }

    .imprint_2 {
      padding: 2em 0 0 0;
    }
        
    h1 {
      font-size: 38px !important;
    }
  }
`;

export {StyledImprint, StyledImprintContainer}