import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledContact = styled.div`
  height: 100%;
  padding: 2em;
`;

const StyledContactContainer = styled.div`
  position: relative;
  height: 100%;
  color: white;
  padding: 4em 2em;
  
  a {
    color: white;
  }
  
  ${down('md')} {
    padding: 2em 0;
        
    h1 {
      font-size: 38px !important;
    }
    
    .contactFields {
      grid-template-columns: 1fr !important;
      grid-row-gap: 1em;
    }
    
    .imageAndText {
      grid-template-columns: 1fr !important;
      
      .meImage {
        img {
          width: 100% !important;
          margin-bottom: 1em;
        }
      }
    }
  }
  
  .contactFields {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 1em;
      margin-top: 2em;
      
      .field {
        padding: 4em;
        display: grid;
        grid-column-gap: 1em;
        grid-template-columns: 40px auto;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        background-color: ${props => props.theme.background.base};
          transition: all ease-in-out 150ms;
        
        &:hover {
          transform: scale(0.98);
          transition: all ease-in-out 150ms;
        }
        
        .fieldIcon {
          font-size: 2em;
          margin-bottom: -7px;
          color: ${props => props.theme.orange.base};
        }
      }
    }

  .imageAndText {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 2em;
    margin-top: 2em;
    align-items: center;
    
    .text {
      display: grid;
      grid-row-gap: 1em;
      
      .highlight {
        color: ${props => props.theme.orange.base} !important;
      }
    }
    
    .meImage {
      img {
        width: 300px;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
      }
    }
    
  }
`;

export {StyledContact, StyledContactContainer}