import React from "react";
import {StyledMenu} from "./styledCommon";
import {Link} from "react-router-dom";
import { ArrowRightShort } from "react-bootstrap-icons";

function Sidebar(props) {

    return (
        <StyledMenu>
            <Link to={'/'}>
                <div className={'menuItem'}>
                    <h2>Home</h2>
                    <div className={'rightIcon'}><ArrowRightShort/></div>
                </div>
            </Link>
            <Link to={'/frida'}>
                <div className={'menuItemNew'}>
                    <h2>Frida</h2>
                    <div className={'newBadge'}><h5>NEU!</h5></div>
                    <div className={'rightIcon'}><ArrowRightShort/></div>
                </div>
            </Link>
            <Link to={'/kontakt'}>
                <div className={'menuItem'}>
                    <h2>Kontakt</h2>
                    <div className={'rightIcon'}><ArrowRightShort/></div>
                </div>
            </Link>
            <div className={'smallMenu'}>
                <Link to={'/impressum'}><h5>Impressum</h5></Link>
                <Link to={'/agb'}><h5>AGB</h5></Link>
                <Link to={'/datenschutz'}><h5>Datenschutz</h5></Link>
            </div>
        </StyledMenu>
    )
}

export default Sidebar;