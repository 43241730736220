import styled from 'styled-components';
import { down } from 'styled-breakpoints';

const StyledHeader = styled.div`
  width: 100%;
  background-color: ${props => props.showHeaderBlur ? props.theme.background.blurred : props.theme.background.dark};
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  backdrop-filter: ${props => props.showHeaderBlur ? 'blur(20px) saturate(180%)' : 'none'};
  z-index: 1;
  border-bottom: 1px solid ${props => props.theme.background.divider};
`;

const StyledHeaderContainer = styled.div`
  padding: 1.5em 2em;
  display: grid;
  grid-column-gap: 1em;
  grid-template-columns: auto 1fr auto auto;
  min-height: 30px;
  align-items: center;
  
  .list {
    display: none;
    color: white;
    font-size: 2em;
    margin-bottom: -10px;
  }
  
  ${down('md')} {
    padding: 0.5em 1em;
    grid-template-columns: 1fr auto auto auto;
    
    .list {
      display: block;
    }
  }
`;

const StyledLogoContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
  align-items: center;
  cursor: pointer;
  justify-self: left;
  color: ${props => props.theme.text.color};
  
  .slogan {
    ${down('md')} {
      display: none;
    }
  }

  .shield {
    margin-bottom: -6px;
    
    &:hover {
      color: ${props => props.theme.text.color};
    }
  }
`;

const StyledMenu = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 1em;
  align-items: center;
  justify-content: center;
  
  .newBadge {
    background-color: ${props => props.theme.pink.base};
    padding: 2px 0.2em;
    border-radius: 4px;
    
    h5 {
      font-weight: 700 !important;
    }
  }
  
  ${down('md')} {
    display: none;
  }

  a {
    color: ${props => props.theme.text.color};
    display: grid;
    grid-template-columns: 1fr auto;
    grid-column-gap: 0.5em;
    align-items: center;
    
    &:hover {
      transition: color ease-in-out 100ms;
      color: ${props => props.theme.orange.base};
      
      .newBadge {
        color: white !important;
      }
    }
  }
`;

const StyledSocials = styled.div`
  color: ${props => props.theme.text.color};
  display: grid;
  grid-column-gap: 0.5em;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: right;
  
  a {
    margin-bottom: -7px;
  }
  
  svg {
    width: 18px;
    height: 18px;
    fill: white;
    color: white;
  }
`;

const StyledContactButton = styled.div`
  background-color: ${props => props.theme.orange.base};
  padding: 0.3em 0.7em;
  border-radius: 4px;
  color: ${props => props.theme.text.color};
  h5 {
    font-weight: 700;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: right;
  cursor: pointer;
  
  ${down('md')} {
    h5 {
      font-weight: 500;
    }
  }
`;

export { StyledHeader, StyledMenu, StyledHeaderContainer, StyledSocials, StyledLogoContainer, StyledContactButton };