import styled from "styled-components";
import { down } from 'styled-breakpoints';

const StyledHero = styled.div`
  width: 100%;
  background-color: ${props => props.theme.background.dark};
  padding: 2em 0;
  z-index: 0;
  border-bottom: 1px solid ${props => props.theme.background.divider};
  
  ${down('md')} {
    padding: 0;
  }
`;

const StyledHeroContainer = styled.div`
  width: 100%;
  height: 600px;
  border-radius: 12px;
  background-color: ${props => props.theme.background.base};
  position: relative;
  
  ${down('md')} {
    height: 400px;
  }
  
  .headline {
    position: absolute;
    color: white;
    display: grid;
    grid-row-gap: 0.5em;
    left: 10%;
    bottom: 20%;
    
    ${down('md')} {
      h1 {
        font-size: 38px !important;
      }
      
      h2 {
        font-size: 16px;
      }
      
      left: 2em;
      bottom: 3em;
    }
    
    .locked {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-column-gap: 0.5em;
      font-size: 1.2em;
      align-items: center;
    }
    
    h1 {
      font-size: 64px;
      line-height: 1;
      font-weight: 700;
    }
    
    h2 {
      line-height: 1;
      color: ${props => props.theme.orange.base};
    }
  }
  
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 80%);
  }
  
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export { StyledHero, StyledHeroContainer }