import styled from "styled-components";

const StyledMenu = styled.div`
  background-color: ${props => props.theme.background.base};
  height: 100%;
  padding: 2em;
  color: white;
  display: grid;
  align-content: flex-start;
  grid-row-gap: 0.5em;
  
  a {
      color: white;
  }
  
  .menuItem {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    
    .rightIcon {
      font-size: 2em;
      margin-bottom: -7px;
      color: ${props => props.theme.orange.base} !important;
    }
  }
  
  .menuItemNew {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-column-gap: 0.5em;
    justify-items: flex-start;
    
    .newBadge {
      background-color: ${props => props.theme.pink.base};
      padding: 0.2em;
      border-radius: 4px;
      
      h5 {
        font-weight: 700 !important;
      }
    }
    
    .rightIcon {
      font-size: 2em;
      margin-bottom: -7px;
      color: ${props => props.theme.orange.base} !important;
    }
  }

  .smallMenu {
    display: grid;
    justify-content: flex-start;
    grid-template-columns: auto auto auto;
    grid-gap: 0.5em;
    padding: 1em 0;
    margin-top: 1em;
    border-top: 2px solid ${props => props.theme.background.divider};
    
    a {
      color: slategrey;
    }
  }
`;

export {StyledMenu}