import React from "react";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomeLayout from "./layouts/home";
import ContactLayout from "./layouts/contact";
import ImprintLayout from "./layouts/imprint";
import AgbLayout from "./layouts/agb";
import DataLayout from "./layouts/datenschutz";
import FridaLayout from "./layouts/frida";

export default function Routes() {
    return (
        <Router>
            <Switch>
                <Route exact path="/"><HomeLayout/></Route>
                <Route exact path="/frida"><FridaLayout/></Route>
                <Route exact path="/kontakt"><ContactLayout/></Route>
                <Route exact path="/impressum"><ImprintLayout/></Route>
                <Route exact path="/agb"><AgbLayout/></Route>
                <Route exact path="/datenschutz"><DataLayout/></Route>
            </Switch>
        </Router>
    )
}