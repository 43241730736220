import React from 'react';
import Header from "../components/header/header";

function DataLayout() {
    return (
        <>
            <Header/>
        </>
    )
}
export default DataLayout;