import React from "react";
import {StyledImprint, StyledImprintContainer} from "./styledImprint";
import {StyledMaxWidthContainer} from "../../styles/styledBody";

function Imprint() {
    return (
        <StyledImprint>
            <StyledMaxWidthContainer>
                <StyledImprintContainer>
                    <h1>Impressum</h1>
                    <div className={'imprint_1'}>
                        <h4>Steffen Thunert</h4>
                        <h4>Str. der Einheit 84</h4>
                        <h4>D-37318 Uder</h4>
                        <h4>Tel.: +49 163 40000 23</h4>
                        <h4>info@thunert-sicherheit.de</h4>
                    </div>
                    <div className={'imprint_2'}>
                        <h4>Haftungshinweis:</h4>
                        <h4>Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Mit Urteil vom 12. Mai 1998 – 312 O 85/98 – „Haftung für Links“ hat das Landgericht Hamburg entschieden, dass man durch die Ausbringung eines Links die Inhalte der gelinkten Seite ggf. mitzuverantworten hat. Dies kann – so das LG – nur dadurch verhindert werden, dass man sich ausdrücklich von diesen Inhalten distanziert. Hiermit distanzieren wir uns deshalb ausdrücklich von allen Inhalten sämtlicher gelinkten Seiten auf unserer Homepage. Diese Erklärung gilt für alle auf unseren Internetseiten angebrachten Links. Wir können für diese fremden Inhalte also kein Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</h4>
                    </div>
                    <div className={'imprint_2'}>
                        <h4>Urheberechtlicher Hinweis:</h4>
                        <h4>Diese Website ist durch das Urheberrecht geschützt. Das Copyright für den Inhalt und der Bilder der Seite, sowie Rechte für Design, Grafik liegt bei Steffen Thunert. Alle dargestellten Fremdlogos, Bilder und Grafiken sind Eigentum der entsprechenden Firmen und unterliegen dem Copyright der entsprechenden Lizenzgeber. Sämtliche auf diesen Seiten dargestellten Fotos, Logos, Texte, Berichte, Scripte und Programmierroutinen, welche Eigenentwicklungen von uns sind oder von uns aufbereitet wurden, dürfen nicht ohne unser Einverständnis kopiert oder anderweitig genutzt werden. Alle Rechte vorbehalten.</h4>
                    </div>
                </StyledImprintContainer>
            </StyledMaxWidthContainer>
        </StyledImprint>
    )
}

export default Imprint;