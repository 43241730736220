import React from "react";
import {StyledMaxWidthContainer} from "../../styles/styledBody";
import {StyledContact, StyledContactContainer} from "./styledContact";
import MeImage from '../../styles/images/fredo-1';
import {EnvelopeFill, TelephoneFill } from "react-bootstrap-icons";

function Contact() {
    return (
        <StyledContact>
            <StyledMaxWidthContainer>
                <StyledContactContainer>
                    <h1>Kontakt</h1>
                    <div className={'imageAndText'}>
                        <div className={'meImage'}><img src={MeImage} alt="me"/></div>
                        <div className={'text'}>
                            <h1>Ich bin für Sie da!</h1>
                            <div className={'highlight'}><h4>Wir können viele Vorteile von FR.ED aufzählen, aber am Besten überzeugen Sie sich selbst: Gerne präsentieren wir Ihnen unser Frühwarnsystem live direkt vor Ort bei Ihnen zu Hause. Testen Sie FR.ED jetzt direkt vor Ort – völlig kostenlos und unverbindlich!</h4></div>
                            <h4>Sie haben noch Fragen zu FR.ED, möchten unser Alarmsystem kostenlos & unverbindlich vor Ort testen oder gerne mehr über uns erfahren? Melden Sie sich!</h4>
                        </div>
                    </div>
                    <div className={'contactFields'}>
                        <a target={'_blank'} rel="noreferrer" href={'tel:+491634000023'}>
                            <div className={'field'}>
                                <div className={'fieldIcon'}><TelephoneFill/></div>
                                <h4>+49 163 40000 23</h4>
                            </div>
                        </a>
                        <a target={'_blank'} rel="noreferrer" href={'mailto:info@thunert-sicherheit.de'}>
                            <div className={'field'}>
                                <div className={'fieldIcon'}><EnvelopeFill/></div>
                                <h4>info@thunert-sicherheit.de</h4>
                            </div>
                        </a>
                    </div>
                </StyledContactContainer>
            </StyledMaxWidthContainer>
        </StyledContact>
    )
}

export default Contact;