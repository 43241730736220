import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  
  body {
      margin: 0;
      font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      line-height: 1.5;
      overflow: auto;
      background-color: ${props => props.theme.background.dark};
  }
  
  .panelOverlay {
    background-color: ${props => props.theme.background.blurred};
  }
    
  a {
    text-decoration: none;
  }
    
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  ul {
    list-style-type: none !important;
    margin: 0;
    padding: 0;
  }
  
  h1 {
    font-size: 48px;
    margin: 0;
    line-height: 1.2;
  }
    
  h2 {
    line-height: 1.2;
    font-size: 28px;
    font-weight: 500;
    margin: 0;
  }
    
  h3 {
    font-weight: 500;
    margin: 0;
  }
    
  h4 {
    font-weight: 500;
    margin: 0;
  }
    
  h5 {
    font-weight: 500;
    margin: 0;
  }
`;

export default GlobalStyle;